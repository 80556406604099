import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom/client'
import {
  RouterProvider,
} from "react-router-dom";
import './index.css'
import './App.css'
// import './fonts.css'
import theme from './theme.tsx';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles'
import { AuthProvider } from './context/AuthContext';
import './assets/language/i18n.ts'
import { router } from './routes/Routes.tsx';
import FormErrorBoundary from "./screens/FormErrorBoundary.tsx";
import * as Sentry from "@sentry/react";
import {SnackbarProvider} from "./components/SnackbarProvider";
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import banner from './assets/img/banner.svg'
import styled  from 'styled-components'
import { useNetworkError } from './hooks/NetworkErrorHook.tsx';

const isDevelopment = import.meta.env.VITE_IS_PRODUCTION !== 'true';

Sentry.init({
  dsn: "https://943aed22d59504a86f15dd21c788284d@o4507178580901888.ingest.us.sentry.io/4507186550996992",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", "https://rinchen-api-nonprod-tlib.wadhwaniai.org/"],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  enabled: !isDevelopment
});
const queryClient = new QueryClient()

const App: React.FC = () => {
  const [isLandscape, setIsLandscape] = useState(window.innerWidth > window.innerHeight);
  const NetworkErrorSnackbar = useNetworkError();

  useEffect(() => {
    const handleOrientationChange = () => {
      const _isLandscape = window.innerWidth > window.innerHeight && window.innerWidth <= 1024
      setIsLandscape(_isLandscape);
    };

    // Add event listener for orientation change
    window.addEventListener('resize', handleOrientationChange);

    // Initial orientation check
    handleOrientationChange();

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleOrientationChange);
    };
  }, []);
  return (
    <div className={`app ${isLandscape ? "landscape" : ""}`}>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          {(isDevelopment) && (
            <Banner src={banner} alt="Banner" />
          )}
          <CssBaseline />
          {/* <MaintenanceMessage /> */}
          <RouterProvider router={router} />
        </AuthProvider>
      </ThemeProvider>
      {NetworkErrorSnackbar}
    </div>
  );
}

import { Box, Typography } from '@mui/material';
function MaintenanceMessage() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        textAlign: 'center',
        padding: 2,
      }}
    >
      <Typography variant="h4" component="h2" gutterBottom>
        The app is under maintenance
      </Typography>
      <Typography variant="body1">
        We are currently performing maintenance. We should be back online shortly.
      </Typography>
    </Box>
  );
}

ReactDOM.createRoot(document.getElementById('root')!).render(
    <FormErrorBoundary>
      <SnackbarProvider>
        <QueryClientProvider client={queryClient}>
          {/* <ReactQueryDevtools /> */}
          <App />
        </QueryClientProvider>
      </SnackbarProvider>
    </FormErrorBoundary>
)

const Banner = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  width: 50px; 
  z-index: 1000;
`;
